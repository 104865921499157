import React, { useState, useContext, useEffect } from 'react';
import Button from '../claim/button';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { jwtToken } from '../../Context/DataContext';
import swal from 'sweetalert';
import { ButtonSpinner } from '../../components/Spinner';
import { UserContext } from '../../Context/UserContext';
import { VehicleDataContext } from '../../Context/VehicleDataContext';
import { useLocation, Link } from 'react-router-dom';
import { getCurrentUTCTime, TimestampToUTC } from '../../utils/DateAndTimeUtils';
import { Form } from 'react-bootstrap';
import infoLogo from '../../assets/img/about.svg';
import { Popover } from '@mui/material';
import '../CSS/Steps.css';

export default function Stepfour() {
  const history = useHistory();
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const { claimObj, plateNumber, VIN, setClaimObj } = useContext(VehicleDataContext);
  const [vehiclePrice, setVehiPrice] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [listLoading, setListLoading] = useState(false);
  const [fileLink, setFileLink] = useState('');

  const [description, setDescription] = useState(null);
  const [descriptionStatus, setDescriptionStatus] = useState('');
  const [isValuationCheck, setIsValuationCheck] = useState(false);
  const [showValuationInfo, setShowValuationInfo] = useState(false);
  const [isMoneyOwingCheck, setIsMoneyOwingCheck] = useState(false);
  const [showMoneyOwingInfo, setShowMoneyOwingInfo] = useState(false);

  // CIN checks
  const [isAutoTrader, setIsAutoTrader] = useState(false);
  const [isCINUploaded, setIsCINUploaded] = useState(false);
  const [fileData, setFileData] = useState(null);

  const location = useLocation();
  const selectedPass = location.state?.selectedPass ? location.state.selectedPass : {};
  const claimSkiped = location.state?.claimSkiped || false;
  const isTrader = location.state?.isTrader || false;
  var priceReg = /^(?=.*[1-9])\d*(\.\d{1,2})?$/;

  //Outage Scenario
  const [disableListPPSR, setDisableListPPSR] = useState(false);
  const [disableListNZTA, setDisableListNZTA] = useState(false);
  const [disableListPersonal, setDisableListPersonal] = useState(false);
  const [matchingListPPSRItem, setMatchingListPPSRItem] = useState();
  const [matchingListNZTAItem, setMatchingListNZTAItem] = useState();
  const [matchingListPersonalItem, setMatchingListPersonalItem] = useState();

  useEffect(() => {
    const getActiveOutage2 = async () => {
      if (token) {
        try {
          const outageResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}get-outagebystatus`, {}, {
            headers: { Authorization: `Bearer ${token}` }
          });

          const currentDateTime = getCurrentUTCTime();
          const isMatchingListPPSR = outageResponse.data.some(item => {
            if (item.API === 'LIST' && item.OutageType === 'PPSR' &&
              currentDateTime > TimestampToUTC(item.OutageStartDateTime) && currentDateTime < TimestampToUTC(item.OutageEndDateTime)) {
              setMatchingListPPSRItem(item);
              return true;
            }
            return false;
          });
          const isMatchingListNZTA = outageResponse.data.some(item => {
            if (item.API === 'LIST' && item.OutageType === 'NZTA' &&
              currentDateTime > TimestampToUTC(item.OutageStartDateTime) && currentDateTime < TimestampToUTC(item.OutageEndDateTime)) {
              setMatchingListNZTAItem(item);
              return true;
            }
            return false;
          });
          const isMatchingListPersonal = outageResponse.data.some(item => {
            if (item.API === 'LIST' && item.OutageType === 'Personal' &&
              currentDateTime > TimestampToUTC(item.OutageStartDateTime) && currentDateTime < TimestampToUTC(item.OutageEndDateTime)) {
              setMatchingListPersonalItem(item);
              return true;
            }
            return false;
          });

          if (isMatchingListPPSR) {
            setDisableListPPSR(true);
          }
          if (isMatchingListNZTA) {
            setDisableListNZTA(true);
          }
          if (isMatchingListPersonal) {
            setDisableListPersonal(true);
          }

        } catch (error) {
          console.error("Error fetching", error);
        }
      }

    };
    getActiveOutage2();
  }, [token]);

  useEffect(() => {
    if (disableListPersonal && location.pathname === '/claim/Not Listed' && location.search === '?activeStep=4') {
      swal({
        title: "Services Unavailable",
        text: "Waka Online NZ Reported Planned Outage - Money Owning Check/Inquiry Check/Listing",
        icon: 'warning',
        dangerMode: 'true'
      }).then(() => {
        history.replace('/details');
      });
    } else if ((disableListPPSR && disableListNZTA) && location.pathname === '/claim/Not Listed' && location.search === '?activeStep=4') {
      swal({
        title: "Services Unavailable",
        text: "NZTA/PPSR Reported Planned Outage - Money Owning Check/Inquiry Check/Listing",
        icon: 'warning',
        dangerMode: 'true'
      }).then(() => {
        history.replace('/details');
      });
    } else if (disableListNZTA && location.pathname === '/claim/Not Listed' && location.search === '?activeStep=4') {
      swal({
        title: "Services Unavailable",
        text: "NZTA Reported Planned Outage - Inquiry Check/Listing",
        icon: 'warning',
        dangerMode: 'true'
      }).then(() => {
        history.replace('/details');
      });
    } else if (disableListPPSR && location.pathname === '/claim/Not Listed' && location.search === '?activeStep=4') {
      swal({
        title: "Services Unavailable",
        text: "PPSR Reported Planned Outage - Money Owning Check/Listing",
        icon: 'warning',
        dangerMode: 'true'
      }).then(() => {
        history.replace('/details');
      });
    }
  });

  // Access the data object
  const backgroundCheck = async () => {
    let inquiryData;
    try {
      inquiryData = await axios.post(
        `${process.env.REACT_APP_API_COMMON}inquiryDetails`,
        { VIN, userID },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      await swal({
        text: "We are unable to retrieve your vehicle details from Waka Kotahi, please check your Plate number/VIN.",
        icon: 'error',
        dangerMode: true
      });
    }

    if (isValuationCheck && inquiryData.status === 200) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_COMMON}vehicle-value`,
          { VIN: VIN },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } catch (error) {
        if (error.response && error.response.status === 404) {
          await swal({
            text: `No vehicle valuation records were found for the specified ${plateNumber || VIN} from CarPrice.`,
            icon: 'error',
            dangerMode: true
          });
        } else {
          await swal({
            text: "We are unable to retrieve your vehicle valuation records from CarPrice, please check your Plate number/VIN.",
            icon: 'error',
            dangerMode: true
          });
        }
      }
    }

    try {
      if (isMoneyOwingCheck) {
        await axios.post(`${process.env.REACT_APP_API_COMMON}moneyowing`,
          { userID, VIN }, { headers: { Authorization: `Bearer ${token}` } }
        );
      }
    } catch (error) {
      await swal({
        text: "We are unable to retrieve details of money owing status for your vehicle, please check your Plate number/VIN.",
        icon: 'error',
        dangerMode: true
      });
    }
  };

  useEffect(() => {
    const regularExpression_badWords = /crap|ugly|brat|fool|fuck|fucking|f\*cking|f\*ck|bitch|b\*tch|shit|sh\*t|fool|dumb|couch potato|arse|arsehole|asshole|\*ssh\*l\*|\*\*\*\*|c\*ck|\*\*\*\*sucker|c\*cks\*ck\*r|\*\*\*\*|c\*nt|dickhead|d\*c\*h\*a\*|\*\*\*\*|f\*c\*|\*\*\*\*wit|f\*ckw\*t|fuk|f\*k|fuking|f\*k\*ng|mother\*\*\*\*er|m\*th\*rf\*ck\*r|\*\*\*\*\*\*|n\*gg\*r|pussy|p\*ssy|\*\*\*\*|sh\*t|wanker|w\*nk\*r|wankers|w\*nk\*rs|whore|wh\*r\*|slag| sl\*g|\*\*\*\*\*|b\*tch|f u c k|f\*c\*|b.i.t.c.h|b\*tch|d-i-c-k|d\*\*\*/gi;
    if (regularExpression_badWords.test(description)) {
      setDescriptionStatus('Pending');
    } else {
      setDescriptionStatus('Approved');
    }
  }, [description]);

  const handleValuationCheckChange = (e) => {
    setIsValuationCheck((isValuationCheck) => e.target.checked);
  };

  const confirmList = async () => {
    // Validation for vehicle price
    if (!vehiclePrice || !priceReg.test(vehiclePrice)) {
      setErrorMessage('The price should be greater than 0 and can have up to two decimal places.');
    } else {
      setErrorMessage('');
      setListLoading(true);
      let claimID = claimObj.ClaimID;
      let listAPIData = {};

      if (claimSkiped) {
        try {
          const pendingClaim = await axios.post(`${process.env.REACT_APP_API_COMMON}pending-claim`, { VIN, userID, isTrader }, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (pendingClaim.status === 200) {
            setClaimObj(pendingClaim.data);
            claimID = pendingClaim.data.ClaimID;
            listAPIData.claimID = pendingClaim.data.ClaimID;
          }
        } catch (error) {
          setListLoading(false);
          console.log(error.response);
          swal({
            text: 'Error creating pending claim!',
            icon: 'error',
            dangerMode: true,
          }).then(() => {
            history.push(`/details`);
          });
        }
      }

      try {
        if (isCINUploaded) {
          const uploadedUrl = await cinUpload(fileData, claimID);
          listAPIData.cinFileURL = uploadedUrl;
        }
      } catch (error) {
        swal({
          text: 'Error uploading CIN file!',
          icon: 'error',
          dangerMode: true,
        }).then(() => {
          setListLoading(false);
        });
      }

      listAPIData.userID = userID;
      listAPIData.claimID = claimSkiped ? listAPIData.claimID : claimObj.ClaimID;
      listAPIData.price = vehiclePrice;
      listAPIData.isAutoTrader = isAutoTrader;
      listAPIData.pass = selectedPass;
      listAPIData.description = description;
      listAPIData.descriptionStatus = descriptionStatus;

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}list`, listAPIData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          await backgroundCheck();
          swal({
            text: 'Vehicle Listed Successfully!',
            icon: 'success',
            dangerMode: true,
          }).then(async () => {
            history.push(`/details`);
          });
        }
      } catch (error) {
        swal({
          text: 'List unsuccessful!',
          icon: 'error',
          dangerMode: true,
        }).then(() => {
          history.push(`/details`);
        });
      } finally {
        setListLoading(false);
      }
    }
  };


  const cinUpload = async (fileData, claimID) => {
    try {
      const cinResopnse = await axios.post(`${process.env.REACT_APP_API_COMMON}cin-signedurl`,
        { userID, claimID: claimID }, { headers: { Authorization: `Bearer ${token}` }, },
      );
      const { signedUrl } = cinResopnse.data;

      const cinUploadResult = await fetch(signedUrl, {
        method: 'PUT',
        body: fileData,
      });

      if (cinUploadResult.ok) {
        const urlObject = new URL(cinUploadResult.url);
        const cinURL = urlObject.origin + urlObject.pathname;
        return cinURL;
      } else {
        swal({
          text: 'Error uploading CIN file!',
          icon: 'error',
          dangerMode: true,
        });
      }
    } catch (error) {
      swal({
        text: 'Error uploading CIN file!',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  const handleFileSelect = async (event) => {
    setFileData(event.target.files[0]);
    if (event.target.files[0]) {
      setIsCINUploaded(true);
      setFileLink(URL.createObjectURL(event.target.files[0]));
    } else {
      setIsCINUploaded(false);
    }
  };

  const handlePreviousPress = () => {
    history.replace(`/claim/Not%20Listed?activeStep=3`, { claimSkiped: true });
  };

  useEffect(() => {

    if (claimSkiped) {
      setIsAutoTrader(isTrader);
    } else {
      setIsAutoTrader(claimObj.IsTrader === "true");
    }
  }, [claimObj.IsTrader, claimSkiped, isTrader]);

  useEffect(() => {
    setIsMoneyOwingCheck(!claimObj.hasOwnProperty('DealerID') ? true : null);
  }, [claimObj]);

  return (
    <div className="container fold">
      <dl className="row">
        <dt>
          <Button className="right-btn d-flex justify-content-center" onClick={() => history.replace('/details')} disabled={listLoading}>
            List Later
          </Button>
        </dt>
        <dt>
          <h4>
            {'Confirm '}
            {plateNumber || VIN}
            {' vehicle listing'}
          </h4>
          &nbsp;
        </dt>
        <h6>{'You can list with the price that you see fit, but buyers can also make offers, and then you can choose the appropriate offer.'}</h6>
        &nbsp;
        <div className='row'>
          <dt className="col-sm-4">Vehicle Price: (NZ$)</dt>
          <dd className="col-sm-8">
            <input
              type="text"
              placeholder="Enter the vehicle price"
              className="form-control"
              min="0"
              onChange={(e) => {
                if (priceReg.test(e.target.value.replace(/[^\d.]/g, ''))) {
                  setErrorMessage('');
                }
                setVehiPrice(e.target.value.replace(/[^\d.]/g, ''));
              }}
              value={vehiclePrice}
            />{' '}
            <p style={{ color: 'red' }}>{errorMessage}</p>
          </dd>
        </div>
        <div className="row">
          <dt className="col-sm-4">Vehicle Valuation:
            <img style={{ marginLeft: '5px' }} src={infoLogo} alt="info logo" className="info_logo" onClick={(e) => setShowValuationInfo(e.currentTarget)} />
            <Popover
              id={showValuationInfo ? 'popover-6' : undefined}
              open={showValuationInfo}
              style={{
                width: '80%',
              }}
              anchorEl={showValuationInfo}
              onClose={() => setShowValuationInfo(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <p className='popover-text'>
                Before enabling the car price listing, kindly double-check your estimation against{' '}
                <a
                  href="https://www.carprice.co.nz/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.carprice.co.nz/
                </a>{' '}
                for the most accurate and up-to-date pricing information.
              </p>
            </Popover>
          </dt>
          <dd className="col-sm-8">
            <Form.Check
              type='checkbox'
              id={`valuation-checkbox`}
              checked={isValuationCheck}
              onChange={handleValuationCheckChange}
            />
          </dd>
        </div>
        <div className="row">
          <dt className="col-sm-4">Money owing:
            <img style={{ marginLeft: '5px' }} src={infoLogo} alt="info logo" className="info_logo" onClick={(e) => setShowMoneyOwingInfo(e.currentTarget)} />
            <Popover
              id={showMoneyOwingInfo ? 'popover-6' : undefined}
              open={showMoneyOwingInfo}
              style={{
                width: '80%',
              }}
              anchorEl={showMoneyOwingInfo}
              onClose={() => setShowMoneyOwingInfo(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <p className='popover-text'>
                Before clicking this, ensure peace of mind by verifying that no security is registered against your vehicle, then proceed with confidence to conduct a New Zealand PPSR check for money owing.
              </p>
            </Popover>
          </dt>
          <dd className="col-sm-8">
            <Form.Check
              type='checkbox'
              id={`valuation-checkbox`}
              checked={isMoneyOwingCheck}
              onChange={(e) => setIsMoneyOwingCheck(e.target.checked)}
            />
          </dd>
        </div>
        <div className='row mb-3'>
          <label className="form-label text-dark">Add the Description</label>
          <textarea className="form-control-textarea step4desc" placeholder="Optional" onChange={(e) => {
            setDescription(e.target.value);
          }} id="exampleFormControlTextarea1" rows="15"></textarea>
        </div>
        <div>
          <input
            type="checkbox"
            id="isAutoTrader"
            name="isAutoTrader"
            checked={isAutoTrader}
            onChange={(e) => setIsAutoTrader(e.target.checked)}
            style={{ marginRight: '20px' }}
            disabled={claimObj.IsTrader === "true" || claimSkiped}
          />
          <label htmlFor="isAutoTrader" style={{ color: 'black' }}>
            Are you an Auto Trader?{' '}
          </label>&emsp;
          {isAutoTrader && <input type="file" accept=".pdf" onChange={handleFileSelect} style={{ margin: '5px 0px 5px -3px', width: '100%' }} />}
          {isCINUploaded && (
            <a href={fileLink} target="_blank" rel="noreferrer">
              {fileData.name}
            </a>
          )}
        </div>
        <div>
          {isAutoTrader && (
            <input
              type="checkbox"
              id="uploadedCIN"
              name="uploadedCIN"
              style={{ marginRight: '10px' }}
              checked={isCINUploaded}
            />
          )}
          {isAutoTrader && (
            <label htmlFor="uploadedCIN" style={{ color: 'black' }}>
              Have you uploaded CIN ?{' '}
            </label>
          )}
        </div>
        <div>
          <Link to={{ pathname: "https://www.consumerprotection.govt.nz/assets/uploads/documents/consumer-information-notice-vehicle-trader.pdf" }} target="_blank">Please see the sample document for CIN</Link>
        </div>
      </dl>

      <Button className="left-btn" bsStyle="danger" variant="danger" onClick={handlePreviousPress} disabled={listLoading}>
        Previous
      </Button>
      {(claimObj.Status === "Active" || claimSkiped) && (
        <Button
          variant="danger"
          bsStyle="danger"
          onClick={confirmList}
          disabled={
            (!claimSkiped && claimObj.IsTrader === "true" && !isCINUploaded) ||
            (!claimSkiped && isAutoTrader && !isCINUploaded) ||
            listLoading ||
            (isTrader && !isCINUploaded)
          }
        >
          {listLoading ? <ButtonSpinner /> : 'List'}
        </Button>
      )}
    </div>
  );
};